import { Component } from "react";
import SpinnerLoader from "../components/loader";

class MainComponent extends Component {
	constructor() {
		super();
		this.texts = {
			placeholderText: `Paieškos žodis (anglų kalba)`,
			searchButtonText: `Ieškoti`,
		};
		this.buttonClick = this.buttonClick.bind(this);
		this.fetchLink = `https://stradivarius.artefaktas.workers.dev/api`;
		this.state = {
			data: null,
			loading: null,
		};
		this.cardStyle = {
			width: 80 + `%`,
		};
		this.cardimgStyle = {
			maxHeight: 30 + `rem`,
		};
	}

	buttonClick() {
		let fieldSelector = document.getElementById("searchInput");
		let fetchUrl = this.fetchLink;
		if (fieldSelector.value !== "") {
			this.setState({ loading: true });
			async function getData() {
				let fetchHeaders = {
					"query-word": String(fieldSelector.value),
				};
				let d = await fetch(fetchUrl, {
					headers: fetchHeaders,
				});
				let jsonData = await d.json();
				return jsonData;
			}
			getData().then((r) => {
				this.setState({
					data: r.photos,
					loading: false,
				});
			});
		}
	}

	render() {
		return (
			<div>
				<input
					id="searchInput"
					className="form-control form-control-lg my-4"
					placeholder={this.texts.placeholderText}
				/>
				<button
					className="btn form-control btn-dark btn-lg"
					onClick={this.buttonClick}
				>
					{this.texts.searchButtonText}
				</button>
				<div className="row">
					{this.state.loading === true ? (
						<div className="col-lg-12 col-md-12 col-sm-12 my-2 col-xs-12 mx-auto">
							<SpinnerLoader />
						</div>
					) : this.state.data !== null ? (
						this.state.data.map((photo, i) => {
							return (
								<div
									className="col-lg-6 col-md-8 col-sm-12 my-2 col-xs-12 mx-auto"
									key={i}
								>
									<div
										className="card my-2 d-block mx-auto"
										style={this.cardStyle}
									>
										<img
											loading="lazy"
											className="card-img-top"
											src={photo.src.large}
											alt={photo.alt}
											style={this.cardimgStyle}
										/>

										<div className="card-body">
											<h5 className="card-title">{photo.photographer}</h5>
											<p className="card-text">{photo.alt}</p>
											<button
												className="btn btn-dark form-control my-4"
												type="button"
												data-toggle="collapse"
												data-target={`#id` + i}
												aria-expanded="false"
												aria-controls="collapseExample"
											>
												Nuorodos
											</button>
											<div className="collapse" id={`id` + i}>
												<p>
													<a
														target="_blank"
														href={photo.src.landscape}
														download={photo.src.landscape}
													>
														{`Landscape: ` + photo.src.landscape}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.large}
														download={photo.src.large}
													>
														{`Large: ` + photo.src.large}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.large2x}
														download={photo.src.large2x}
													>
														{`Large 2x: ` + photo.src.large2x}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.medium}
														download={photo.src.medium}
													>
														{`Medium: ` + photo.src.medium}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.original}
														download={photo.src.original}
													>
														{`Original: ` + photo.src.original}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.portrait}
														download={photo.src.portrait}
													>
														{`Portrait: ` + photo.src.portrait}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.small}
														download={photo.src.small}
													>
														{`Small: ` + photo.src.small}
													</a>
												</p>
												<p>
													<a
														target="_blank"
														href={photo.src.tiny}
														download={photo.src.tiny}
													>
														{`Tiny: ` + photo.src.tiny}
													</a>
												</p>
												<a
													target="_blank"
													href={photo.url}
													className="btn btn-primary"
												>
													Pixabay nuoroda
												</a>
											</div>
										</div>
									</div>
								</div>
							);
						})
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

export default MainComponent;
